<template>
  <div class="px-2 container">
    <div class="row">
      <div class="col-12 col-sm-8">
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          :aspect-ratio="aspectRatio"
          :guides="false"
          v-if="imgSrc != null"
          preview=".preview"
        >
        </vue-cropper>
        <div class="actions my-3 d-flex flex-wrap">
          <k-button
            size="sm"
            class="mb-2 mb-sm-0"
            v-for="button in aspectRatios"
            :key="button.AspectRatio"
            @click="setAspactRatio(button.AspectRatio)"
          >
            {{ button.DisplayName }}
          </k-button>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <h4 class="mb-2">Kép előnézete</h4>
        <div class="preview cropped-image mb-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'image-cropper',
  data() {
    return {
      aspectRatio: 1 / 1,
      aspectRatios: [
        {
          AspectRatio: 1 / 1,
          DisplayName: '1:1',
        },
        {
          AspectRatio: 3 / 4,
          DisplayName: '3:4',
        },
        {
          AspectRatio: 4 / 3,
          DisplayName: '4:3',
        },
        {
          AspectRatio: 16 / 9,
          DisplayName: '16:9',
        },
        {
          AspectRatio: 9 / 16,
          DisplayName: '9:16',
        },
      ],
    };
  },
  mounted() {},
  created() {},
  methods: {
    setAspactRatio(number) {
      this.$refs.cropper.setAspectRatio(number);
    },
    CropImage() {
      let croppedImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$emit('crop-image', croppedImg);
    },
  },
  computed: {},
  watch: {},
  components: {},
  props: {
    imgSrc: {
      type: String,
    },
  },
};
</script>
<style scoped>
.actions::v-deep .k-btn {
  margin-right: 8px;
  min-width: 50px;
}
.cropped-image::v-deep {
  max-width: 100%;
}
.cropped-image::v-deep img {
  max-width: 100%;
}
.preview {
  width: 100%;
  height: 200px;
  margin: 0 auto;
  overflow: hidden;
}
</style>
